/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const cashDiscountSuccess = {
  title: "success stories",
  subTitle: "Our merchant",
};

export const cashDiscountHero = {
  title: "A POS that offsets card processing fees? Yes please",
  subtext:
    "Save hundreds per month compared to traditional processing with SpotOn Dual Pricing, our leading cash discount program that lets you accept nearly all forms of payment while offsetting card transaction fees.",
  heroImg: "cashDiscountHero.png",
  imageClassName: "fixed-height-600",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/assist/demo",
  },
  fatTitle: "Assist",
  fatTitleClass: "font-large",
};

export const cashDiscountWhiteGlove = {
  mainTitle: "White-glove service and support",
  title: "Real support from real people",
  content:
    "SpotOn’s dual pricing program is ideal for restaurants, retail, automotive, health & beauty, and professional services. Our team will work closely with you to optimize a dual pricing program that fits your business, and then train you and your staff to get the most from it. Once you’re up and running, we’ll continue to be there for you with around-the-clock technical local support, included at no extra cost.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/assist/demo",
  },
};

export const cashDiscountLargeFeatures = {
  sectionTitle: "Smarter tech to boost your profit margins",
  featureBlocks: [
    {
      blockTitle: "Cash or card—you win",
      blockImg: "cash-a.png",
      blockSubTitle: "",
      blockList: [
        "Increase average spend by as much as by 20% with credit versus cash transactions",
        "Reduce fraud risk with an EMV- and NFC-enabled terminal",
        "Grow revenue with SpotOn’s all-in-one digital platform",
        "Keep your customers happy by offering a discount on cash purchases",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/assist/demo",
      },
    },
    {
      blockTitle: "How it works",
      blockSubTitle: "",
      blockImg: "cash-b.png",
      blockList: [
        "SpotOn equips you with a cutting-edge point-of-sale that automatically offers two prices, one for cash, one for credit—just like at gas stations",
        "We help you adjust your credit card prices to offset processing costs",
        "We help you communicate your pricing to customers, making your program compliant and your customers happy",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/assist/demo",
      },
    },
    {
      blockTitle: "Partner with a company you can trust",
      blockSubTitle: "",
      blockImg: "cash-c.png",
      blockList: [
        "Peace of mind: SpotOn’s dual pricing program is a true cash discount program that your customers will easily understand",
        "Cost savings: Offset the cost of credit card payments",
        "Transparency: Know exactly how much you’re saving",
        "Tools to grow: Built-in marketing and review management",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/assist/demo",
      },
    },
  ],
};

export const cashDiscountTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes. I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const cashDiscountCTA = {
  title: "Book your consultation.",
  ctaImg: "sidekick-cta.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/assist/demo",
  },
};

export const cashDiscountTemplate = [
  {
    content: "Reservations & waitlisting through your website",
    desc: "Keep your tables full and reduce walk-aways by letting your guests book directly from you.",
    img: "Online_reservation.png",
  },
  {
    content: "Sidekick with Google listing",
    desc: "Attract more guests by enabling them to Sidekick a table or add themselves to your guest list right from a web search.",
    img: "Google_listings.png",
  },
  {
    content: "Host application for iPad",
    desc: "Boost efficiency of your front-of-house by easily managing reservations, waitlists, and walk-ins.",
    img: "Host_application.png",
  },
  {
    content: "Server & table management",
    desc: "Streamline operations, reduce labor costs, and turn tables faster with powerful front-of-house tools.",
    img: "Service_management.png",
  },
  {
    content: "Web portal access",
    desc: "Easily manage your front-of-house, wherever you are, including across multiple location.",
    img: "Online_reservation.png",
  },
  {
    content: "Customer analytics",
    desc: "Know who your guests are and where they’re coming from, so you can drive repeat visits.",
    img: "reporting.png",
  },
];
